import React  from 'react';

import styled, { css } from 'styled-components';



const Button = ({
  primary,
  className,
  ...props
}) => {



  return (
    <StyledButton 
      primary={primary}
      className={className}
      {...props}
    >
      {props.children}
    </StyledButton>
  )
};

Button.propTypes = {

};

export default Button;

const StyledButton = styled.button`
  appearance: none;
  outline: none;
  font-size: 2.4em;
  line-height: 1em;
  min-height: 54px;
  padding: 0.5em 1em;
  text-align: center;
  cursor: pointer;
  ${({ primary }) =>
    primary &&
    css`
      background-color: ${({ theme }) => theme.colors.Primary};
      color: #fff;
      transition: all 0.3s ease-in-out;
      &:hover {
        opacity: 0.75;
      }
    `
  };

  ${({ transparent }) => 
    transparent &&
    css`
      color: ${({ theme }) => theme.colors.LtBlue};
    `
  };

`;
