// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-1010-js": () => import("./../../../src/pages/1010.js" /* webpackChunkName: "component---src-pages-1010-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-clubhouse-js": () => import("./../../../src/pages/clubhouse.js" /* webpackChunkName: "component---src-pages-clubhouse-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leaderboard-js": () => import("./../../../src/pages/leaderboard.js" /* webpackChunkName: "component---src-pages-leaderboard-js" */),
  "component---src-pages-on-tour-js": () => import("./../../../src/pages/on-tour.js" /* webpackChunkName: "component---src-pages-on-tour-js" */),
  "component---src-pages-quiet-please-js": () => import("./../../../src/pages/quiet-please.js" /* webpackChunkName: "component---src-pages-quiet-please-js" */),
  "component---src-pages-tee-box-js": () => import("./../../../src/pages/tee-box.js" /* webpackChunkName: "component---src-pages-tee-box-js" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */)
}

