import { css } from 'styled-components';


const sizes = {
  desktopXL: 1366,
  desktop: 1024,
  tablet: 768,
  mobile: 412,
  footer: '900px'
};

const CONTENT_WIDTH = '850px';


export default {
  colors: {
    // TODO: replace with actual branding colors
    Primary: '#144a63',
    Secondary: '#fdac00',
    Tertiary: '',
    DkBlue: '#55a3c7',
    LtBlue: '#6ac7f1',
    Grey: '#8b9ba2',
    Orange: '#e0ab25',
    DarkYellow: '#d6cf2b',
    BrightYellow: '#fcff01'
  },
  content: { CONTENT_WIDTH },
  breakpoints: sizes,
};