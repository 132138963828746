import React, { memo  } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import { toggleMenu } from 'actions/menu';

const Menu = ({ menuIsOpen, dispatch }) => {

  const handleLinkClick = () => {
    if (!menuIsOpen) {
      document.getElementById('___gatsby').classList.add('fixed');
    } else {
      document.getElementById('___gatsby').classList.remove('fixed');
    }
    
    dispatch(toggleMenu());
  };

  return (
    <Container className={menuIsOpen ? 'open' : ''}>
      <LinkList>
        <li>
          <StyledLink onClick={handleLinkClick} to="/clubhouse">Clubhouse</StyledLink>
        </li>
        <li>
          <StyledLink onClick={handleLinkClick} to="/1010">1010</StyledLink>
        </li>
        <li>
          <StyledLink onClick={handleLinkClick} to="/on-tour">On Tour</StyledLink>
        </li>
        <li>
          <StyledLink onClick={handleLinkClick} to="/leaderboard">Leaderboard</StyledLink>
        </li>
        <li>
          <StyledLink onClick={handleLinkClick} to="/quiet-please">Quiet Please</StyledLink>
        </li>
        <li>
          <StyledLink onClick={handleLinkClick} to="/tee-box">The Tee Box</StyledLink>
        </li>
        <li>
          <StyledExtLink onClick={handleLinkClick} href="https://proshop.linksbrewing.com/" target="_blank">The Pro Shop</StyledExtLink>
        </li>

      </LinkList>
      <LegalContainer>
        <p className={'bebas'}>© 2022 Links Brewing Co., LLC</p>
        {/* <p className={'bebas'}><a href="#">Privacy Policy</a></p> */}
      </LegalContainer>
    </Container>
  );
};
Menu.propTypes = {
  menuIsOpen: PropTypes.bool
};

const mapStateToProps = ({ menu }) => ({
  menuIsOpen: menu.menuIsOpen,
});
const withConnect = connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
);
export default compose(
  memo,
  withConnect,
)(Menu);


const Container = styled.nav`
  width: 100%;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.Primary};
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding-top: 3em;
  transform: translateX(100%);
  transition: all 0.2s ease-in-out;
  > a img {
    width: 90%;
    height: auto;
    max-width: 150px;
  }
  &.open {
    transform: translateX(0%);
  }
  @media all and (min-width: 700px) {
    padding-top: 8em;
  }
`;

const LinkList = styled.ul`
  display: flex;
  flex-direction: column;
  margin: auto 0;
  li {
    margin-bottom: 3em;
    display: flex;
    justify-content: center;
  }
  li:not(:last-of-type) {
    position: relative;
    :after {
      content:"";
      width: 30px;
      height: 2px;
      background-color: ${({ theme }) => theme.colors.Orange};
      position: absolute;
      left: 50%;
      bottom: -35%;
      transform: translateX(-50%);
    }
  }
  @media all and (min-width: 700px) {
    margin: auto 0;
    align-self: center;
    li {
      margin-bottom: 3.5em;
    }
  }
`;

const StyledLink = styled(Link)`
  color: white;
  font-family: 'Averia Serif Libre', serif;
  text-transform: uppercase;
  font-size: 3.2em;
  @media all and (min-width: 700px) {
    font-size: 4.2em;
  }
`;
const StyledExtLink = styled(Link)`
  color: white;
  font-family: 'Averia Serif Libre', serif;
  text-transform: uppercase;
  font-size: 3.2em;
  @media all and (min-width: 700px) {
    font-size: 4.2em;
  }
`;

const LegalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3em;
  margin-bottom: 3em;
  p {
    color: ${({ theme }) => theme.colors.LtBlue};
  }
`;