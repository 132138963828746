import React from 'react';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import styled from 'styled-components';


import SocialLnks from 'components/global/SocialLinks';
import EmailSignUp from 'components/global/EmailSignUp';

const Footer = () => {



  return (
    <Container>

      <EmailSignUp />
      <SocialLnks />
      <LazyLoadImage className={'footer-image-desktop'} src={'/images/footer-image.png'} alt="Image of golfer drinking a beer wrapped in a fairway divot." />
      <LegalContainer>
        <p>519 East General Robinson St.<br/> Pittsburgh, PA 15212</p>
        <p><strong>Hours:</strong> By Appointment Only</p>
        <p><a href="tel:412-209-3224">412-209-3224</a></p>
        <p><a href="mailto:info@linksbrewing.com">info@linksbrewing.com</a></p>
        <p className={'bebas'}>© 2022 Links Brewing Co., LLC</p>
        {/* <p className={'bebas'}><a href="#">Privacy Policy</a></p> */}
      </LegalContainer>
    </Container>
  );
};

Footer.propTypes = {
};

export default Footer;


const Container = styled.footer`
  width: 100%;
  padding: 3em 0;
  background-color: ${({ theme }) => theme.colors.LtBlue};
  background-image: url('/images/footer-image.png');
  background-repeat: no-repeat;
  background-size: auto 50%;
  background-position: center bottom;
  .social-links {
    margin: 4em 0;
    padding-bottom: 18em;
  }
  .footer-image-desktop {
    display: none;
  }
  display: flex;
  flex-direction: column;

  @media all and (min-width: ${({ theme }) => theme.breakpoints.footer}) {
    background-image: none;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    &:after {
      content:"";
      background-color: ${({ theme }) => theme.colors.Orange};
      width: 35%;
      height: 100%;
      display: block;
      position: absolute;
      top: 0px;
      right: 0px;
      z-index: 0;
    }
    .footer-image-desktop {
      display: block;
      height: 110%;
      width: auto;
      position: absolute;
      bottom: 0px;
      right: 38%;
      transform: translateX(50%);
      z-index: 1;
    }
    .email-signup {
      width: 40%;
    }
    .social-links {
      width: 31%;
      padding-bottom: 2em;
      position: relative;
      z-index: 1;
      margin-top: 0em;
    }
  }
`;

const LegalContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 18em;
  background-color: ${({ theme }) => theme.colors.Primary};
  width: 80%;
  align-self: center;
  padding: 1.5em 0;
  p {
    color: white;
    margin: 0.25em 0;
    text-align: center;
  }
  strong {
    font-weight: 700;
  }
  @media all and (min-width: ${({ theme }) => theme.breakpoints.footer}) {
    margin-left: auto;
    width: 31%;
    position: relative;
    z-index: 1;
    margin-top: auto;
    background-color: transparent;
  }
`;