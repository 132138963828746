/**
 *
 * IndexPage
 *
 *
 */

import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Button from 'components/elements/Button';
import { useCookies } from 'react-cookie';


const GlobalStyle = createGlobalStyle`

  #___gatsby {
    position: fixed;
  }
`;

const AgeVerify = () => {

  const [cookies, setCookie, removeCookie] = useCookies(['age_verified']);

  const getExpireDate = () => {
    var today = new Date();
    var expireDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()+14);
    return expireDate;
  };

  const handleConfirm = () => {
    var expireDate = getExpireDate();

    setCookie('age_verified', true, {
      expires: expireDate,
      path: '/'
    })
  };

  const handleDeny = () => {
    var expireDate = getExpireDate();

    setCookie('age_verified', false, {
      expires: expireDate,
      path: '/'
    });
  };

  const ageVerified = cookies['age_verified'];

  return (
    <>
      {
        (ageVerified === 'false' || typeof ageVerified === 'undefined') ? 
          <Container>
            <img src={'/images/main-logo.svg'} className={'logo'} alt="Logo for Links Brewing Co." />
            <CtaContainer>
              <h1>What's your handicap... we mean age!</h1>
              <h2>You must be 21+ to enter our clubhouse.</h2>
              { 
                !cookies['age_verified'] && (
                  <>
                    <StyledButton primary onClick={handleConfirm}>Yes, I am 21</StyledButton>
                    <StyledButton primary onClick={handleDeny}>No, I am under 21</StyledButton>
                  </>
                )
              }
            </CtaContainer>
            <img className={'golfers'} src={'/images/golfers.png'} alt="Graphic depicting four golfers"/>
            <img className={'golfers-desktop'} src={'/images/desktop-golfers.png'} alt="Graphic depicting four golfers"/>
            <GlobalStyle />
          </Container>
      :
        null
      }
    </>
  )
};

AgeVerify.propTypes = {

};

export default AgeVerify;

const Container = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  background-color: white;
  padding-top: 4em;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999999;
  .logo {
    max-width: 400px;
    width: 80%;
    margin-bottom: 4em;
  }
  img {
    width: 100%;
    height: auto;
    &.golfers-desktop {
      display: none;
    }
    pointer-events: none;
  }
  @media all and (min-width: 700px) {
    img.golfers {
      display: none;
    }
    img.golfers-desktop {
      display: block;
    }
  }
`;

const CtaContainer = styled.section`
  margin: 4em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  h1,h2 {
    text-align: center;
    color: ${({ theme }) => theme.colors.Primary};
  }
  h1 {
    font-size: 3.8em;
  }
  h2 {
    font-size: 2em;
    margin: 1em 0;
    font-family: 'Averia Serif Libre', serif;
  }
  @media all and (min-width: 700px) {
    margin-bottom: -8em;
  }
`;

const StyledButton = styled(Button)`
  text-transform: uppercase;
  font-family: 'Bebas Neue', serif;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.Primary};
  font-size: 2.4em;
  border: 2px solid ${({ theme }) => theme.colors.Primary};
  padding: 0.25em 1em;
  margin: 0.5em 0;
  min-width: 250px;
  &:hover {
    background-color: ${({ theme }) => theme.colors.Primary};
    color: white;
    opacity: 1;
  }
`;
