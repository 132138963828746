import React, { useState, useEffect } from 'react';

import styled, { css, keyframes } from 'styled-components';



const Hamburger = ({
  onClick,
  isOpen
}) => {
  const [loaded, setLoaded] = useState(false);
  
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 500);
  },[loaded]);


  return (
    <HamburgerContainer className={ loaded ? 'fade-in hamburger' : 'hamburger' } isOpen={isOpen} onClick={onClick}>
      <Line top className={isOpen ? 'open' : ''} />
      <Line middle className={isOpen ? 'open' : ''} />
      <Line bottom className={isOpen ? 'open' : ''} />
    </HamburgerContainer>
  )
};

Hamburger.propTypes = {

};

export default Hamburger;

const HamburgerContainer = styled.button`
  width: 40px;
  height: 40px;
  position: relative;
  appearance: none;
  outline: none;
  background-color: transparent;
  padding: 0px;
  opacity: 0;
  z-index: 11;
  &.fade-in {
    opacity: 1;
  }
`;

const Line =  styled.span`
  height: 2px;
  position: absolute;
  ${( { top } ) =>
    top &&
    css`
      background-color: ${({ theme }) => theme.colors.Primary};
      width: 30px;
      left: 0px;
      animation: ${topOpen} 0.4s forwards;
      &.open {
        animation: ${topClosed} 0.4s forwards;
        background-color: ${({ theme }) => theme.colors.Orange};
      }
    `
  };

  ${( { middle } ) =>
    middle &&
    css`
      background-color: ${({ theme }) => theme.colors.Secondary};
      width: 25px;
      top: 50%;
      left: 0px;
      animation: ${midOpen} 0.4s forwards;
      transform: translateY(-50%);
      &.open {
        background-color: ${({ theme }) => theme.colors.Orange};
        animation: ${midClosed} 0.4s forwards;
      }
    `
  };

  ${( { bottom } ) =>
    bottom &&
    css`
      background-color: ${({ theme }) => theme.colors.Grey};
      width: 20px;
      left: 0px;
      animation: ${botOpen} 0.4s forwards;
      &.open {
        background-color: ${({ theme }) => theme.colors.Orange};
        animation: ${botClosed} 0.4s forwards;
      }
    `
  };
  @media all and (min-width: 800px) {
    left: auto;
    right: 0px;
  }
`;

const topClosed = keyframes`
  0% {
    top: 30%;
    transform: rotate(0deg) translateY(-50%);
  }
  50% {
    top: 50%;
    transform: rotate(0deg) translateY(-50%);
  }
  100% {
    top: 50%;
    transform: rotate(45deg) translateY(-50%);
  }
`;

const topOpen = keyframes`
  0% {
    top: 50%
    transform: rotate(45deg) translateY(-50%);
  }
  50% {
    top: 50%;
    transform: rotate(0deg) translateY(-50%);
  }
  100% {
    top: 30%;
    transform: rotate(0deg) translateY(-50%);
  }
`;

const midClosed = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

const midOpen = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const botClosed = keyframes`
  0% {
    width: 20px;
    top: 70%;
    transform: rotate(0deg) translateY(-50%);
  }
  50% {
    width: 20px;
    top: 50%;
    transform: rotate(0deg) translateY(-50%);
  }
  100% {
    width: 30px;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);
  }
`;

const botOpen = keyframes`
  0% {
    width: 30px;
    top: 50%;
    transform: rotate(-45deg) translateY(-50%);
  }
  50% {
    width: 30px;
    top: 50%;
    transform: rotate(0deg) translateY(-50%);
  }
  100% {
    width: 20px;
    top: 70%;
    transform: rotate(0deg) translateY(-50%);
  }
`;
