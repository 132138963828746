import React  from 'react';

import styled from 'styled-components';


const SocialLinks = () => {



  return (
    <Container className={'social-links'}>
      <h4>Follow Us To The Links</h4>
      <ul>
        <li>
          <a href="https://www.instagram.com/linksbrewing/" target="_blank" rel="noopener noreferrer">
            <img src={'/icons/instagram.png'} alt="Instagram Logo"/>
          </a>
        </li>
        <li>
          <a href="https://twitter.com/LinksBrewing" target="_blank" rel="noopener noreferrer">
            <img src={'/icons/twitter.png'} alt="Twitter Logo" />
          </a>
        </li>
        {/* <li>
          <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <img src={'/icons/youtube.png'} alt="Youtube Logo"/>
          </a>
        </li>
        <li>
          <a href="https://www.facebook.com/Links-Brewing-Company-1693617630915557" target="_blank" rel="noopener noreferrer">
            <img src={'/icons/facebook.png'} alt="Facebook Logo" />
          </a>
        </li> */}
      </ul>
    </Container>
  );
};

SocialLinks.propTypes = {
  
};

export default SocialLinks;


const Container = styled.section`
  width: 100%;
  /* height: 6em; */
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-size: 3em;
    color: ${({ theme }) => theme.colors.Primary};
  }

  ul {
    display: flex;
    margin: 2em 0;
    li {
      margin: 0 2em;
    }
    img {
      width: auto;
      height: 35px;
    }
  }
  @media all and (min-width: ${({ theme }) => theme.breakpoints.footer}) {
    h4 {
      padding: 0 2em;
      text-align: center;
    }
    ul {
      flex-wrap: wrap;
      width: 40%;
      li {
        width: 50%;
        margin: 0;
        display: flex;
        justify-content: space-around;
        margin-top: 1em;
      }
    }
  }
`;

