import React, { memo } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import mapStyles from 'utils/mapTheme';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';

import { toggleMap } from 'actions/map';
import Button from 'components/elements/Button';
import MapInfoWindow from 'components/elements/MapInfoWindow';

const Map = ({ mapIsOpen, dispatch }) => {

  const handleCloseClick = () => {
    dispatch(toggleMap());
  };

  const containerStyle = {
    width: '100%',
    height: 'calc(100% - 50px)',
    position: 'absolute',
    top: '50px'
  };
   
  const center = {
    lat: 40.449150,
    lng: -79.998070
  };

  const infoAnchor = {
    lat: 40.449150,
    lng: -79.998070
  }

  

  const options = {
    styles: mapStyles,
    mapTypeControl: false,
    scaleControl: false,
    rotateControl: false,
    fullscreenControl: false
  };

  const markerIcon = {
    url: '/icons/pin-flag.svg',
    scaledSize: {width: 45, height: 45},
  };

  return (
    <Container className={mapIsOpen ? 'open' : ''}>
      <CloseButton onClick={handleCloseClick} aria-label="Close Map Button" />
      {
        mapIsOpen && (
          <LoadScript
            googleMapsApiKey="AIzaSyAKrT-BQGovnRIYjF4mJuu0FsIO55raBG0"
          >
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={13}
                options={options}
              >
                <InfoWindow
                  position={infoAnchor}
                  options={{
                    pixelOffset: {width: 0, height: -60}
                  }}
                >
                  <MapInfoWindow />
                </InfoWindow>
                <Marker 
                  position={center}
                  icon={markerIcon}
                >
                </Marker>
              </GoogleMap>
          </LoadScript>
        )
      }
    </Container>
  );
};
Map.propTypes = {
  mapIsOpen: PropTypes.bool
};

const mapStateToProps = ({ map }) => ({
  mapIsOpen: map.mapIsOpen,
});
const withConnect = connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
);
export default compose(
  memo,
  withConnect,
)(Map);


const Container = styled.aside`
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  z-index: 10;
  background-color: ${({ theme }) => theme.colors.Primary};
  transform: translateX(100%);
  transition: all 0.5s ease-in-out;
  &.open {
    transform: translateX(0%);
  }
`;

const CloseButton = styled(Button)`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: transparent;
  &:before,
  &:after {
    content:"";
    width: 30px;
    height: 2px;
    background-color: white;
    position: absolute;
    top: 50%;
    left: 50%;
  }
  &:before {
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }

  &:after {
    transform: translateX(-50%) translateY(-50%) rotate(-45deg);
  }

`;