import React, { useState } from 'react';
import { useMailChimpForm } from "use-mailchimp-form";

import styled from 'styled-components';

import EmailInput from 'components/elements/EmailInput';
import Button from 'components/elements/Button';


const EmailSignUp = () => {

  const url = "https://linksbrewing.us10.list-manage.com/subscribe/post?u=f44437a8e21a88cf52576b26d&amp;id=084c23d9b4";

  const useFormFields = initialState => {
    const [fields, setValues] = useState(initialState);
  
    return [
      fields,
      function(event) {
        setValues({
          ...fields,
          [event.target.id]: event.target.value
        });
      }
    ];
  };

  const { status, message, handleSubmit } = useMailChimpForm(url);
  const [params, handleFieldChange] = useFormFields({
    EMAIL: ""
  });



  return (
    <Container className={'email-signup'}>
      <h3>Join Our Club</h3>
      <p>Membership has its privileges.</p>
      <p>Enjoy yours.</p>
      <form onSubmit={event => handleSubmit(event, params)}>
        <EmailContainer>
          <EmailInput 
            id="EMAIL"
            type="email"
            value={params.EMAIL}
            onChange={handleFieldChange}
            aria-label={"Search Terms"}
          />
          <img src={'/icons/putt.png'} alt="Golf ball being putt into a golf hole"/>
          <Button 
            className={'bebas'} 
            primary
            transparent
          >
            Submit
          </Button>
          <StatusContainer>
            {
              status.error && (
                <span className={'error'} dangerouslySetInnerHTML={{__html: message}} />
              )
            }
            {
              status.success && (
                <span>Well played. You're in.</span>
              )
            }
          </StatusContainer>
        </EmailContainer>
      </form>
      <div className='order-container'>
        <h4>Order Beer and Merchandise Online</h4>
        <StyledLink href="https://proshop.linksbrewing.com/" target="_blank">Shop Now</StyledLink>
      </div>
    </Container>
  );
};

EmailSignUp.propTypes = {

};

export default EmailSignUp;


const Container = styled.section`
  width: 100%;
  /* height: 6em; */
  display: flex;
  flex-direction: column;
  align-items: center;
  h3,p {
    color: white;
  }
  h3 {
    font-size: 3.6em;
  }
  form {
    width: 100%;
  }
  .order-container {
    display: flex;
    flex-direction: column;
    margin-top: 2em;
    > h4 {
      color: ${({ theme }) => theme.colors.Primary};
      font-size: 2.8em;
      margin-bottom: 1em;
    }
    > a {
      align-self: center;
    }
  }
  @media all and (min-width: ${({ theme }) => theme.breakpoints.footer}) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
    h3 {
      width: 100%;
      text-align: center;
    }
    p {
      font-size: 1.5em;
      color: ${({ theme }) => theme.colors.Primary};
      margin-top: 0.5em;
      &:last-of-type {
        margin-left: 0.25em;
      }
    }
    .order-container {
      margin-top: 4em;
      h4 {
        color: white;
      }
    }
  }
`;

const EmailContainer = styled.div`
  margin-top: 2em;
  padding: 0 3em;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;

  input {
    width: 100%;
  }
  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 120px;
    align-self: center;
    margin-right: 2.5em;
  }
  @media all and (min-width: ${({ theme }) => theme.breakpoints.footer}) {
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 0 2em;
    input {
      width: 100%;
      max-width: 300px;
    }
    button {
      width: 100%;
      max-width: 110px;
      padding: 0.5em 0;
      align-self: center;
    }
    img {
      order: 2;
      margin-top: 3em;
      max-width: 200px;
    }
  }
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2em;
  width: 100%;
  padding: 0 1em;
  span {
    font-size: 1.4em;
    color: ${({ theme }) => theme.colors.Primary};
    text-align: center;
    a {
      display: none;
    }
    &.error {
      color: red;
    }
  }
  @media all and (min-width: ${({ theme }) => theme.breakpoints.footer}) {
    max-width: 300px;
  }
`;

const StyledLink = styled.a`
  appearance: none;
  outline: none;
  font-size: 2.4em;
  line-height: 1em;
  padding: 0.5em 1em;
  text-align: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.Primary};
  color: ${({ theme }) => theme.colors.LtBlue};
  transition: all 0.3s ease-in-out;
  font-family: 'Bebas Neue', serif;
`;
