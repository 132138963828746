import React from 'react';

import styled from 'styled-components';



const EmailInput = ({ ...props }) => {



  return (
    <Input 
      type="email" 
      placeholder="Enter Your Email" 
      className={'bebas'}
      {...props}
    />
  )
};

EmailInput.propTypes = {

};

export default EmailInput;

const Input = styled.input`
  outline: none;
  border: 1px solid ${({ theme }) => theme.colors.Primary};
  color: ${({ theme }) => theme.colors.Primary};
  background-color: transparent;
  padding: 0.5em 3.5em 0.5em 0.5em;
  font-size: 2em;
  &::placeholder {
    color: ${({ theme }) => theme.colors.Primary};
  }
`;
