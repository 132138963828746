import {
  TOGGLE_MAP,
} from 'actions/map';

const initialState = {
  mapIsOpen: false,
};

export default function mapReducer(state = initialState, action) {
  const { type, ...actions } = action;
  switch (type) {
    case TOGGLE_MAP:
      return {
        ...state,
        mapIsOpen: !state.mapIsOpen,
      };
    default:
      return state;
  }
}