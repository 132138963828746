import React  from 'react';

import styled from 'styled-components';

const MapInfoWindow = () => {



  return (
    <Container>
      <img src={'/images/main-logo.svg'} alt=""/>

      <p className={'bebas'}>
        <a href={'https://www.google.com/maps/dir//519+E+General+Robinson+St,+Pittsburgh,+PA+15212/@40.4491617,-80.0003535,17z/data=!4m9!4m8!1m0!1m5!1m1!1s0x8834f3fb562ede75:0x5cf2dc607166238b!2m2!1d-79.9981595!2d40.4491617!3e0'} target="_blank" rel="noopener noreferrer">Get Directions</a>
      </p>
    </Container>
  )
};

MapInfoWindow.propTypes = {

};

export default MapInfoWindow;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2em;
  img {
    width: 100%;
    height: auto;
    max-width: 150px;
  }
  p {
    margin: 0.5em 0;
    color: ${({ theme }) => theme.colors.Primary};
  }
`;
