/**
 *
 * Layout
 *
 *
 */

import React, { memo, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import styled, {
  ThemeProvider,
} from 'styled-components';
import PropTypes from 'prop-types';

import SEO from 'components/seo';
import Header from 'components/global/Header';
import Menu from 'components/global/Menu';
import Footer from 'components/global/Footer';
import Map from 'components/global/Map';
import AgeVerify from 'components/global/AgeVerify';

import { useCookies } from 'react-cookie';

import Theme from 'utils/theme';

import '../styles/screen.scss';


const Layout = ({
  children,
  dispatch
}) => {

  const previewMode = process.env.GATSBY_PREVIEW_MODE;
  

  const [cookies, setCookie, removeCookie] = useCookies(['age_verified']);

  const [showVerify, setShowVerify] = useState(false);

  const ageVerified = cookies['age_verified'];

  useEffect(() => {
    if (ageVerified !== 'true' && !previewMode) {
      setShowVerify(true)
    }
  }, [ageVerified]);


  return (
    <ThemeProvider theme={Theme}>
      {
        showVerify && (
          <AgeVerify />
        )
      }
      
      <SEO />
      <Header />
      <Menu />
      {children}
      <Map />
      <Footer />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  dispatch: PropTypes.func.isRequired,
};

const withConnect = connect(
  dispatch => ({ dispatch }),
);

export default compose(memo, withConnect)(Layout);



const Container = styled.div`

`;
