import React, { memo } from 'react';

import styled from 'styled-components';

import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';

import Hamburger from 'components/elements/Hamburger';

import { toggleMenu } from 'actions/menu';

const Header = ({ menuIsOpen, dispatch }) => {


  const handleBurgerClick = () => {
    dispatch(toggleMenu());
    if (!menuIsOpen) {
      document.getElementById('___gatsby').classList.add('fixed');
    } else {
      document.getElementById('___gatsby').classList.remove('fixed');
    }
  };

  return (
    <Container>
      <Hamburger onClick={handleBurgerClick} isOpen={menuIsOpen} />
        {
          menuIsOpen ?
            <Link to="/">
              <img src={'/icons/main-logo-white.svg'} onClick={handleBurgerClick} className={'logo'} alt="Logo for Links Brewing Co." />
            </Link>
            :
            <Link to="/">
              <img src={'/images/main-logo.svg'} className={'logo'} alt="Logo for Links Brewing Co." />
            </Link>
        }
    </Container>
  );
};

Header.propTypes = {
  menuIsOpen: PropTypes.bool
};

const mapStateToProps = ({ menu}) => ({
  menuIsOpen: menu.menuIsOpen,
});
const withConnect = connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
);
export default compose(
  memo,
  withConnect,
)(Header);


const Container = styled.header`
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 3em;
  .logo {
    height: 45px;
    width: auto;
    display: block;
    position: relative;
    z-index: 11;
  }
  .hamburger {
    position: absolute;
    top: 50%;
    left: 30px;
    transform: translateY(-50%);
  }
  @media all and (min-width: 800px) {
    .logo {
      height: 60px;
    }
    .hamburger {
      left: auto;
      right: 30px;
    }
  }
`;